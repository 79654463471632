import React, { FC, PropsWithChildren, useCallback } from 'react';
import { Button, ButtonProps } from '@ui/button';
import { isFunction } from '@common/methods/isFunction';
import { Tooltip } from '../../tooltip';
import { trans } from '../../../modules/translation/translate';
import { Loader } from '../../loader/loader';

export interface UserActionsButtonProps extends Omit<ButtonProps, 'onClick'> {
	tooltip?: string;
	loadingId?: string;
	onClick?: () => void;
}

export const UserActionsButton: FC<
	PropsWithChildren<UserActionsButtonProps>
> = ({ tooltip, loadingId, ...props }) => {
	const onClick = useCallback(() => {
		if (isFunction(props.onClick)) {
			props.onClick();
		}
	}, [props]);
	if (!tooltip) {
		return (
			<Button {...props} onClick={onClick} onlyIcon={!props.label}>
				{loadingId && <Loader module={loadingId} />}
			</Button>
		);
	}
	return (
		<Tooltip onModal content={trans({ label: tooltip })}>
			<Button {...props} onClick={onClick} onlyIcon={!props.label}>
				{loadingId && <Loader module={loadingId} />}
			</Button>
		</Tooltip>
	);
};
