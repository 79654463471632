import { trans } from '@legacyApp/client/modules/translation/translate';
import { RaceCompetititor } from '../types/RaceCompetititor';

export const racePositionString = (position: RaceCompetititor['position']) => {
	switch (position) {
		case 1: {
			return trans({ label: '1st' });
		}
		case 2: {
			return trans({ label: '2nd' });
		}
		case 3: {
			return trans({ label: '3rd' });
		}
		default: {
			return trans({
				label: '{{position}}th',
				options: {
					position,
				},
			});
		}
	}
};
