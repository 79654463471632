import React from 'react';
import JSONPath from 'JSONPath';
import { HiddenData } from 'src/ui/components/hidden-data';
import { Trans } from '@legacyApp/client/modules/translation/translate';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { sentryError } from '@legacyApp/client/modules/app/sentryService';
import { HIDDEN_USER } from '@common/constants/user/HIDDEN_USER';
import { TABLE_COLUMNS } from './constants/columns';

class ColumnsClass {
	constructor() {
		this.tablesColumns = {};
		this.columns = TABLE_COLUMNS;
	}

	parseJsonPathToColumnId(id) {
		return id.replace('$.', '').split('.').join('_');
	}

	handleTableValue(col, el, props) {
		let value = JSONPath({
			path: col.id,
			json: el,
		})[0];
		if (value === HIDDEN_USER && !col.parseHidden) {
			return <HiddenData />;
		}
		if (col.callback) {
			value = col.callback(value, el, props);
		}
		if (value === undefined || value === null) {
			if (col.id === '$.game.name') {
				// eslint-disable-next-line no-undef
				if (NEXT_CONFIG.DEV) {
					// console.log('Table column game logo error',{col, el, value});
					alert('Table column game logo error');
				}
				sentryError(new Error('Table column game logo error'), {
					col,
					el,
					value,
				});
			}
			return '-';
		}
		return value;
	}

	getColumns(type) {
		if (!this.tablesColumns[type]) {
			this.tablesColumns[type] = this.columns.filter(
				(el) => el.types && el.types.indexOf(type) > -1,
			);
		}
		return this.tablesColumns[type];
	}

	getRowDetails = (id) => {
		switch (id) {
			default: {
				return (
					<span>
						<Trans label={'No details'} />
					</span>
				);
			}
		}
	};
}

export const columnsService = new ColumnsClass();
