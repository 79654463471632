import { FC, PropsWithChildren, useCallback } from 'react';
import { CopyToClipboard as _CopyToClipboard } from 'react-copy-to-clipboard';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { showAlert } from '@legacyApp/client/store/alerts/alerts.actions';
import { trans } from '@legacyApp/client/modules/translation/translate';

export const CopyToClipboard: FC<
	PropsWithChildren<{
		value: string;
	}>
> = ({ value, children }) => {
	const dispatch = useAppDispatch();

	const _handleCopied = useCallback(
		(value: string) => {
			dispatch(
				showAlert(
					'success',
					trans({
						label: '{{value}} - Copied!',
						options: {
							value,
						},
					}),
				),
			);
		},
		[dispatch],
	);

	return (
		<_CopyToClipboard text={value} onCopy={_handleCopied}>
			{children}
		</_CopyToClipboard>
	);
};
