import { connect } from 'react-redux';
import { openModal } from '../../../../../modules/modals/store/modal.actions';
import {
	friendsAcceptRequest,
	friendsCheckStatus,
	friendsIgnore,
	friendsRejectRequest,
	friendsRemove,
	friendsSendRequest,
	friendsUnignore,
} from '../../../store/friends/friends.actions';
import { privateMessagesOpenActive } from '../../../store/privateMessages/privateMessages.actions';
import { UserActionsList } from './userActions.list';

const mapStateToProps = (state) => {
	return {
		myId: state.user.id,
		myPreferences: state.preferences,
		isModerator: state.user.isModerator || state.user.isAdmin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		openModal: (name, props) => dispatch(openModal(name, 'userActions', props)),
		checkFriendStatus: (uuid) => dispatch(friendsCheckStatus(uuid)),
		sendFriendRequest: (uuid) => dispatch(friendsSendRequest(uuid)),
		acceptFriend: (uuid) => dispatch(friendsAcceptRequest(uuid)),
		rejectFriend: (uuid) => dispatch(friendsRejectRequest(uuid)),
		deleteFriend: (uuid) => dispatch(friendsRemove(uuid)),
		ignoreUser: (uuid) => dispatch(friendsIgnore(uuid)),
		unignoreUser: (uuid) => dispatch(friendsUnignore(uuid)),
		openPrivateChat: (uuid) => dispatch(privateMessagesOpenActive(uuid)),
		dispatch,
	};
};

const UserActionsListContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(UserActionsList);

export { UserActionsListContainer };
