import React from 'react';
import PropTypes from 'prop-types';
import {
	faBan,
	faCheckSquare,
	faCommentDollar,
	faEnvelope,
	faTimesCircle,
	faTrashAlt,
	faUnlockAlt,
	faUserClock,
	faUserLock,
	faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { sentryError } from '../../../modules/app/sentryService';
import {
	FRIEND,
	IGNORED,
	IGNORING,
	REQUEST,
	SENT,
} from '../../../store/friends/friends.constants';
import { allowedFriendStatus } from '../../../modules/app/friendsService';
import { enhanceComponent } from '../../../wrappers/enhanceComponent';
import { AwesomeIcon } from '../../../../../common/components/icons/AwesomeIcon';
import { MODAL_ID } from '../../../../../common/constants/modal/ids.modal.constants';
import { openModal } from '../../../../../modules/modals/store/modal.actions';
import { second } from '../../../modules/app/time';
import { UserActionsButton } from './userActions.button';

const INTERVAL = 30 * second;

class UserActionsList extends React.PureComponent {
	static propTypes = {
		user: PropTypes.object.isRequired,
		status: PropTypes.string,
		myId: PropTypes.any,
		myPreferences: PropTypes.object,
		className: PropTypes.string,
		checkFriendStatus: PropTypes.func,
		openModal: PropTypes.func,
		sendFriendRequest: PropTypes.func,
		ignoreUser: PropTypes.func,
		unignoreUser: PropTypes.func,
		deleteFriend: PropTypes.func,
		acceptFriend: PropTypes.func,
		rejectFriend: PropTypes.func,
		openPrivateChat: PropTypes.func,
		actionsType: PropTypes.string,
		refresh: PropTypes.any,
		dispatch: PropTypes.func,
		isModerator: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = {
			status: props.status,
			user: props.user,
			allowedStatuses: allowedFriendStatus,
			refresh:
				typeof props.refresh === 'number'
					? props.refresh
					: props.refresh
					? INTERVAL
					: false,
		};
		this.interval = null;
	}

	componentDidMount() {
		if (
			this.state.user &&
			this.props.myId &&
			this.props.myId !== this.state.user.uuid &&
			(this.props.status === undefined || !this.state.user.login)
		) {
			this.checkFriendStatus();
		}
		if (this.state.refresh) {
			this.interval = setInterval(() => {
				this.checkFriendStatus();
			}, this.state.refresh);
		}
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	checkFriendStatus = (uuid) => {
		if (!uuid && this.state.user) {
			uuid = this.state.user.uuid;
		}
		if (!uuid) {
			return;
		}
		this.props.checkFriendStatus(uuid).then((result) => {
			this.setStatus(result.state);
			this.setState({
				user: result.user,
			});
		});
	};

	tip = () => {
		this.props.openModal(MODAL_ID.TIP, {
			parameter: this.state.user.login,
		});
	};

	addFriend = (uuid = this.state.user.uuid) => {
		if (this.isAddingFriendLocked()) {
			return;
		}
		this.props.sendFriendRequest(uuid).then(this.setStatus);
	};

	deleteFriend = (uuid = this.state.user.uuid) => {
		this.props.deleteFriend(uuid).then(this.setStatus);
	};

	acceptFriend = (uuid = this.state.user.uuid) => {
		this.props.acceptFriend(uuid).then(this.setStatus);
	};

	rejectFriend = (uuid = this.state.user.uuid) => {
		this.props.rejectFriend(uuid).then(this.setStatus);
	};

	ignore = (uuid = this.state.user.uuid) => {
		const action =
			this.state.status === IGNORING
				? this.props.unignoreUser
				: this.props.ignoreUser;
		action(uuid).then(this.setStatus);
	};

	sendMessage = () => {
		if (!this.state.user) {
			sentryError('No this.state.user in UserActionsList.jsx', {
				state: this.state,
				props: this.props,
			});
		}
		this.props.openPrivateChat({
			user: this.state.user,
			channelId: this.state.user.uuid,
		});
	};

	setStatus = (status) => {
		this.setState({
			status,
		});
	};

	isAddingFriendLocked = () => {
		if (!this.state.user) {
			return true;
		}
		return (
			this.state.user.preferences &&
			this.state.user.preferences.community &&
			this.state.user.preferences.community.block_all_friend_requests
		);
	};

	render() {
		console.log('UserActionsList.jsx - render ->', {
			props: this.props,
			state: this.state,
		});

		if (!this.state.user) {
			return false;
		}
		if (
			!this.props.myId ||
			this.props.myId === this.state.user.uuid ||
			this.state.status === IGNORED ||
			!this.state.user.uuid
		) {
			return false;
		}
		if (this.state.status === IGNORING) {
			return (
				<UserActionsButton
					tooltip={this.state.status !== IGNORING ? 'Ignore' : 'Unignore'}
					onClick={this.ignore}
					name={'user_ignore'}
					loadingId={`set${
						this.state.status !== IGNORING ? 'I' : 'Uni'
					}gnoreUser`}
					iconBefore={
						this.state.status !== IGNORING ? (
							<AwesomeIcon icon={faUserLock} />
						) : (
							<AwesomeIcon icon={faUnlockAlt} />
						)
					}
				/>
			);
		}

		return (
			<>
				{this.state.status === REQUEST &&
					(!this.props.actionsType || this.props.actionsType === REQUEST) && (
						<>
							<UserActionsButton
								tooltip={'Accept friend request'}
								onClick={this.acceptFriend}
								name={'user_accept_friend'}
								loadingId={'sendFriendRequestAccept'}
								themeType={'positive'}
								iconBefore={<AwesomeIcon icon={faCheckSquare} />}
							/>
							<UserActionsButton
								tooltip={'Reject friend request'}
								onClick={this.rejectFriend}
								name={'user_reject_friend'}
								loadingId={'sendFriendRequestReject'}
								themeType={'negative'}
								iconBefore={<AwesomeIcon icon={faTimesCircle} />}
							/>
						</>
					)}
				{(this.state.status === FRIEND ||
					(this.state.user.preferences &&
						this.state.user.preferences.community &&
						this.state.user.preferences.community.allow_messages_from_all &&
						this.props.myPreferences.community.allow_messages_from_all.bool)) &&
					!this.props.actionsType && (
						<UserActionsButton
							tooltip={'Send private message'}
							onClick={this.sendMessage}
							name={'user_message'}
							iconBefore={<AwesomeIcon icon={faEnvelope} />}
						/>
					)}
				{this.state.status !== IGNORING && !this.props.actionsType && (
					<UserActionsButton
						tooltip={'Send tip'}
						onClick={this.tip}
						name={'user_tip'}
						iconBefore={<AwesomeIcon icon={faCommentDollar} />}
					/>
				)}
				{![SENT, FRIEND, REQUEST, IGNORING].some(
					(el) => el === this.state.status,
				) &&
					!this.props.actionsType && (
						<UserActionsButton
							tooltip={
								this.isAddingFriendLocked()
									? 'User blocked friend requests'
									: 'Add to friends list'
							}
							onClick={this.addFriend}
							disabled={this.isAddingFriendLocked()}
							name={'user_add_friend'}
							loadingId={'sendFriendRequest'}
							iconBefore={<AwesomeIcon icon={faUserPlus} />}
						/>
					)}
				{this.state.status === SENT && (
					<UserActionsButton
						tooltip={'Friend request sent'}
						disabled={true}
						name={'user_add_friend'}
						iconBefore={<AwesomeIcon icon={faUserClock} />}
					/>
				)}
				<UserActionsButton
					tooltip={this.state.status !== IGNORING ? 'Ignore' : 'Unignore'}
					onClick={this.ignore}
					name={'user_ignore'}
					loadingId={`set${
						this.state.status !== IGNORING ? 'I' : 'Uni'
					}gnoreUser`}
					iconBefore={
						this.state.status !== IGNORING ? (
							<AwesomeIcon icon={faUserLock} />
						) : (
							<AwesomeIcon icon={faUnlockAlt} />
						)
					}
				/>
				{this.state.status === FRIEND && !this.props.actionsType && (
					<UserActionsButton
						tooltip={'Remove from friends'}
						onClick={this.deleteFriend}
						name={'user_delete_friend'}
						loadingId={'removeFriend'}
						iconBefore={<AwesomeIcon icon={faTrashAlt} />}
					/>
				)}
				{this.props.isModerator && (
					<UserActionsButton
						tooltip={'Ban user'}
						name={'user_ban'}
						onClick={() =>
							this.props.dispatch(
								openModal(MODAL_ID.BAN_USER, 'userActions.list', {
									login: this.props.user.login,
								}),
							)
						}
						iconBefore={<AwesomeIcon icon={faBan} />}
					/>
				)}
			</>
		);
	}
}

UserActionsList = enhanceComponent({
	UserActionsList,
});

export { UserActionsList };
