import styled, { css } from 'styled-components';

import { boxNoHeight } from '@legacyApp/client/modules/style/defaults';
import { StyledUserName } from '@ui/user-name/user-name.styled';

import { BetIdProps } from '@common/components/bets/betId/BetIdSimple';
import { StyledOnlineIcon } from '@common/components/user/UserLogin/OnlineIcon';

export const resultMargin = '5px';

const boxStyle = (props) => css`
	${boxNoHeight};
	padding: ${resultMargin}!important;
	position: relative;

	.new-badge {
		position: absolute;
		top: -21px;
		right: 5px;
		color: ${props.theme.colors.inputBackgroundSolid};
	}

	.game-icon {
		font-size: 14px;
	}

	${props.session
		? css`
				@media (max-width: ${props.theme.media.maxWidthTablet}) {
					.chat-content-private & {
						max-width: 310px;
					}
				}
		  `
		: ''}
`;

export const BetIdStyle = styled.div.attrs({
	className: 'bet-id',
}) <{ box?: boolean; session?: boolean; $onlyName?: BetIdProps['onlyName'] }>`
  ${(props) =>
		props.$onlyName
			? css`
          text-transform: capitalize;
        `
			: ''}
  ${(props) => (props.box ? boxStyle(props) : '')}
  cursor: pointer;
`;

export const BoxTitleStyle = styled.div.attrs({
	className: 'bet-box--title flex-space-between flex-vertical-center',
})`
	line-height: 1.2;
	margin-bottom: ${resultMargin};
	cursor: pointer;

	${StyledUserName},
	.hidden-data {
		margin-right: 3px;
		font-size: 11px !important;

		i:not(${StyledOnlineIcon}) {
			font-size: 11px !important;
		}

		img {
			height: 13px;
		}
	}
`;

export const BoxTitleDetailsStyle = styled.div.attrs({
	className: 'bet-box--title__details flex-start-column',
})`
	.game-icon {
		margin-top: -2px;
		margin-right: 4px;
	}

	.hint {
		font-size: 10px !important;
	}
`;
