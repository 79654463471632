import React, { FC, useCallback, useRef } from 'react';
import transactionService from '../../../../../modules/transactions/transactionsService';
import { TableLoadingRow } from './tableLoadingRow';
import { TableRowContent } from './tableRowContent';
import {
	StyledTable,
	StyledTableNoScroll,
	StyledTableRow,
	StyledTBody,
	StyledTHead,
} from './Table.styled';
import { TableRow } from './tableRow';
import { TableProps } from './tableProps';
import { NoResultsElement } from './noResultsElement';

export const Table: FC<TableProps> = (props) => {
	const tableRef = useRef();
	const tbodyRef = useRef();
	const {
		classic,
		data,
		fulfilled,
		noScroll,
		tableStyle,
		id,
		sortProps,
		headCellAdd,
		headOnClick,
		bodyCellAdd,
		bodyOnClick,
		columns,
		rowMargin,
		rowCompareKeys,
		tableCompareKeys,
		loadingId,
		noResultsText,
		additionalData,
		highlightRow,
		rowClass,
	} = props;

	const Tag = {
		table: classic ? 'table' : 'div',
		thead: classic ? 'thead' : 'div',
		tbody: classic ? 'tbody' : 'div',
		tr: classic ? 'tr' : 'div',
	};
	const isNoResults = (!data || !data.length) && fulfilled !== false;
	const TableClass = noScroll ? StyledTableNoScroll : StyledTable;

	const getRowKey = useCallback(
		(el) => {
			return `${id}_${transactionService.getCompareData(el, tableCompareKeys)}`;
		},
		[id, tableCompareKeys],
	);

	return (
		// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
		<TableClass
			// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
			as={Tag.table}
			ref={tableRef}
			style={tableStyle}
			name={`table-${id}`}
			$noResults={!data.length}
			className={`table-${id}`}
		>
			<StyledTHead as={Tag.thead} data-name="table-head">
				<StyledTableRow as={Tag.tr}>
					<TableRowContent
						sortProps={sortProps}
						cellAdd={headCellAdd}
						onClick={headOnClick}
						classic={classic}
						columns={columns}
					/>
				</StyledTableRow>
			</StyledTHead>
			{/*@ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.*/}
			<StyledTBody
				// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
				as={Tag.tbody}
				$rowMargin={rowMargin}
				$length={
					(data?.length || 1) +
					(additionalData?.length ? additionalData?.length + 1 : 0)
				}
				data-name="table-body"
				ref={tbodyRef}
			>
				{!isNoResults &&
					data.map((el, index) => (
						<TableRow
							onClick={bodyOnClick}
							sortProps={sortProps}
							compareKeys={rowCompareKeys}
							highlightRow={highlightRow}
							rowClass={rowClass}
							cellAdd={bodyCellAdd}
							classic={classic}
							columns={columns}
							el={el}
							index={index}
							key={getRowKey(el)}
						/>
					))}
				{additionalData &&
					additionalData.map((el, index) => {
						return (
							<TableRow
								key={getRowKey(el)}
								cellAdd={bodyCellAdd}
								onClick={bodyOnClick}
								compareKeys={rowCompareKeys}
								el={el}
								classic={classic}
								isAdditional={index === 0}
								index={index + data.length}
								highlightRow={highlightRow}
								rowClass={rowClass}
								columns={columns}
							/>
						);
					})}
				<TableLoadingRow
					id={loadingId}
					isData={!isNoResults && !!data?.length}
					message={
						isNoResults && (
							<NoResultsElement
								classic={classic}
								noResultsText={noResultsText}
							/>
						)
					}
				/>
			</StyledTBody>
		</TableClass>
	);
};
