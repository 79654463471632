import { Loadable } from '@legacyApp/client/components/loader/loadable/loadable';

export const PrizeColumn = Loadable(
	{
		loader: () => import(/* webpackChunkName: "PrizeColumn" */ './PrizeColumn'),
	},
	'./PrizeColumn',
);
export const WagerColumn = Loadable(
	{
		loader: () => import(/* webpackChunkName: "WagerColumn" */ './WagerColumn'),
	},
	'./WagerColumn',
);
export const RankColumn = Loadable(
	{ loader: () => import(/* webpackChunkName: "RankColumn" */ './RankColumn') },
	'./RankColumn',
);
