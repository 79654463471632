import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { StyledTableRow } from './Table.styled';
import { TableRowContent, TableRowContentProps } from './tableRowContent';

import { TableProps } from './tableProps';

export interface TableRowProps extends TableRowContentProps {
	index?: number;
	highlightRow?: TableProps['highlightRow'];
	rowClass?: TableProps['rowClass'];
	isAdditional?: boolean;
}

export const TableRow = ({
	el,
	index,
	columns,
	compareKeys,
	classic,
	highlightRow,
	rowClass,
	cellAdd,
	isAdditional,
}: TableRowProps) => {
	const className = useStateEffect(
		useCallback(() => {
			return classnames(
				{
					'initial-blink': highlightRow,
					'highlight-row': el?.highlight,
				},
				rowClass,
				el?.rowClass,
			);
		}, [el?.highlight, el?.rowClass, highlightRow, rowClass]),
	);

	if (!el) {
		return null;
	}

	return (
		<StyledTableRow
			$rowIndex={index}
			as={classic ? 'tr' : 'div'}
			className={className}
			$isAdditional={isAdditional}
			// data-id={`${id}_${transactionService.getCompareData(el, tableCompareKeys)}`}
		>
			<TableRowContent
				cellAdd={cellAdd}
				classic={classic}
				columns={columns}
				compareKeys={compareKeys}
				el={el}
			/>
		</StyledTableRow>
	);
};
