import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { Explorers } from '@common/constants/currency/settings/explorers';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { getTimestamp } from '../../client/modules/app/timeService';
import { sentryError } from '../../client/modules/app/sentryService';
import { CurrencyNetworkType } from '../../types/wallet/CurrencyType';
import { day } from '../../client/modules/app/time';

export const getExplorerUrl = ({
	network,
	txid,
	date,
	object,
}: {
	network: CurrencyNetworkType;
	txid: string;
	date: number;
	object: any;
}): string => {
	if (!network) {
		return '';
	}

	let explorer: string = Explorers[network];

	if (explorer === '') {
		if (network === 'xrp') {
			explorer =
				!date ||
				date < roundNumber(getTimestamp() - (25 * day) / 1000, 0, 'floor')
					? 'https://xrpcharts.ripple.com/#/transactions/{{txid}}'
					: 'https://blockchair.com/ripple/transaction/{{txid}}?from={{from}}';
		}
	}

	if (!explorer) {
		sentryError(new Error('Unsupported wallet transaction explorer'), {
			network,
			txid,
			date,
			object,
		});

		return '';
	}

	return explorer
		.replace('{{txid}}', txid)
		.replace('{{from}}', NEXT_CONFIG.PRODUCTION ? 'wolfbet' : 'test-1234');
};
