import { FC, PropsWithChildren } from 'react';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import { Tooltip } from '@legacyApp/client/components/tooltip';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { config } from '@legacyApp/client/config';
import { StyledProfit } from './StyledProfit';
import { CurrencyValue } from './CurrencyValue';

export interface AmountWrapperProps {
	value: number;
	currency?: CurrencyType;
	isProfitColor?: boolean;
	isCurrencyInFront?: boolean;
	state?: string;
}

export const AmountWrapper: FC<PropsWithChildren<AmountWrapperProps>> = ({
	value,
	currency,
	isProfitColor,
	isCurrencyInFront,
	state,
	children,
}) => {
	return (
		<CurrencyValue currency={currency} isCurrencyInFront={isCurrencyInFront}>
			<StyledProfit $state={state} $disable={!isProfitColor} $value={value}>
				<Tooltip
					content={mathService.currencyValue(
						value,
						undefined,
						undefined,
						config.decimals.balance,
					)}
				>
					{children}
				</Tooltip>
			</StyledProfit>
		</CurrencyValue>
	);
};
