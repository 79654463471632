import React, { FC, useCallback } from 'react';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { Image } from '@common/components/default/Image/Image';
import {
	BetSlipIcon,
	StyledGameIcon,
} from '@modules/sports/components/BetSlipSection/BetSlipDetails/BetSlipIcon';
import { AwesomeIcon } from '@common/components/icons/AwesomeIcon';
import { theme } from '@legacyApp/client/modules/style/theme';
import { useStateEffect } from '@common/hooks/useStateEffect';
import plinko from '../../../../../../../public/img/games/plinko.svg';
import plinkoActive from '../../../../../../../public/img/games/plinko_active.svg';
import hilo from '../../../../../../../public/img/games/hilo.svg';
import hiloActive from '../../../../../../../public/img/games/hilo_active.svg';
import dice from '../../../../../../../public/img/games/dice.svg';
import diceActive from '../../../../../../../public/img/games/dice_active.svg';
import limbo from '../../../../../../../public/img/games/limbo.svg';
import limboActive from '../../../../../../../public/img/games/limbo_active.svg';
import Icon from '../../../../../../common/components/icons/Icon';
import { GameType } from '../../../constants/availableGames';
import { GameIconStyle, StyledFlashIcon } from './gameIcon.style';

const isNameValid = (name) => {
	return name && typeof name === 'string';
};

const GameIcon_: FC<{
	name: GameType;
	active: boolean;
}> = ({ name, active }) => {
	switch (name) {
		case GameType.DICE: {
			return active ? (
				<Image src={diceActive} alt="diceActive" />
			) : (
				<Image src={dice} alt="dice" />
			);
		}
		case GameType.PLINKO: {
			return active ? (
				<Image src={plinkoActive} alt="plinkoActive" />
			) : (
				<Image src={plinko} alt={GameType.PLINKO} />
			);
		}
		case GameType.HILO: {
			return active ? (
				<Image src={hiloActive} alt="hiloActive" />
			) : (
				<Image src={hilo} alt="hilo" />
			);
		}
		case GameType.LIMBO: {
			return active ? (
				<Image src={limboActive} alt="limboActive" />
			) : (
				<Image src={limbo} alt="limbo" />
			);
		}
		case GameType.SPORTS: {
			return <BetSlipIcon />;
		}
		default: {
			return (
				<StyledGameIcon>
					<Icon
						id={'slots/slots'}
						color={active ? theme.colors.active : 'white'}
					/>
				</StyledGameIcon>
			);
		}
	}
};

const GameIcon: FC<{
	name: string;
	active?: boolean;
	noWrapper?: boolean;
	flash?: boolean;
}> = ({ name, active, noWrapper, flash }) => {
	const getName = useCallback((): GameType => {
		return isNameValid(name)
			? (name.toLowerCase() as GameType)
			: (name as GameType);
	}, [name]);
	const gameName = useStateEffect(getName);
	if (!name) {
		return null;
	}
	if (noWrapper) {
		return <GameIcon_ name={gameName} active={active} />;
	}
	return (
		<GameIconStyle
			className={classnames({
				['game-icon__' + gameName]: isNameValid(name),
			})}
		>
			{flash && (
				<StyledFlashIcon>
					<AwesomeIcon icon={faBolt} />
				</StyledFlashIcon>
			)}
			<GameIcon_ name={gameName} active={active} />
		</GameIconStyle>
	);
};

export default GameIcon;
