import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Icon } from '@common/components/icons';
import { filterArrayDuplicates } from '@legacyApp/client/modules/app/appService';
import { BetSlipDetailsEventType } from '../../../types/BetSlipDetails/BetSlipDetailsEventType';

export const BetSlipIcon: FunctionComponent<{
	list?: BetSlipDetailsEventType[];
	allowMultipleIcons?: boolean;
}> = ({ list, allowMultipleIcons }) => {
	if (!list?.length) {
		return (
			<StyledGameIcon>
				<Icon color={'white'} id={'sports_bet'} />
			</StyledGameIcon>
		);
	}
	const sportSlugs = filterArrayDuplicates(
		list.map((el) => el.offer.event.sport.slug),
	);
	if (sportSlugs.length > 1 && !allowMultipleIcons) {
		return (
			<StyledGameIcon>
				<Icon color={'white'} id={'sports/multi'} />
			</StyledGameIcon>
		);
	}
	return (
		<>
			{sportSlugs.map((sportSlug) => (
				<StyledGameIcon key={sportSlug}>
					<Icon
						noIcon={<Icon color={'white'} id={'sports_bet'} />}
						color={'white'}
						id={`sports/${sportSlug}`}
						noPlaceholder
					/>
				</StyledGameIcon>
			))}
		</>
	);
};

export const StyledGameIcon = styled.div`
	display: inline-block;
	line-height: 0;
	position: relative;
`;
