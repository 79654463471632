import {
	FRIEND,
	IGNORED,
	IGNORING,
	NONE,
	REQUEST,
	SENT,
} from '../../store/friends/friends.constants';

export const allowedFriendStatus = [
	IGNORED,
	IGNORING,
	NONE,
	SENT,
	FRIEND,
	REQUEST,
];
