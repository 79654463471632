import React, { FC } from 'react';
import { Loader } from '@legacyApp/client/components/loader/loader';
import { LoadingIdType } from '@common/constants/fetch/loading.ids';
import { StyledTableLoader, StyledTableRow } from './Table.styled';

export const TableLoadingRow: FC<{
	id: LoadingIdType;
	message?: any;
	isData?: boolean;
}> = ({ id, message, isData }) => {
	if (!id) {
		return null;
	}
	const Component = isData ? StyledTableLoader : StyledTableRow;
	return (
		<Component>
			<Loader module={id} message={message} />
		</Component>
	);
};
