export enum Explorers {
	ada = 'https://cardanoscan.io/transaction/{{txid}}',
	bnb = 'https://bscscan.com/tx/{{txid}}',
	bch = 'https://blockchair.com/bitcoin-cash/transaction/{{txid}}?from={{from}}',
	btc = 'https://blockchair.com/bitcoin/transaction/{{txid}}?from={{from}}',
	doge = 'https://blockchair.com/dogecoin/transaction/{{txid}}?from={{from}}',
	eth = 'https://blockchair.com/ethereum/transaction/{{txid}}?from={{from}}',
	dot = 'https://polkadot.subscan.io/extrinsic/{{txid}}',
	etc = 'https://blockscout.com/etc/mainnet/tx/{{txid}}',
	ltc = 'https://blockchair.com/litecoin/transaction/{{txid}}?from={{from}}',
	trx = 'https://tronscan.org/#/transaction/{{txid}}',
	xlm = 'https://blockchair.com/stellar/transaction/{{txid}}?from={{from}}',
	optim = 'https://optimistic.etherscan.io/tx/{{txid}}',
	op = 'https://optimistic.etherscan.io/tx/{{txid}}',
	matic = 'https://polygonscan.com/tx/{{txid}}',
	pol = 'https://polygonscan.com/tx/{{txid}}',
	xrp = '', // check getExplorerUrl.ts:20,
	sol = 'https://solscan.io/tx/{{txid}}',
}
