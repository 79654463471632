import React from 'react';
import PropTypes from 'prop-types';
import { enhanceComponent } from '../../wrappers/enhanceComponent';
import { UserActionsListContainer } from './userActions.list';
import { StyledUserActions } from './userActions.styled';

class UserActions extends React.PureComponent {
	static propTypes = {
		user: PropTypes.object.isRequired,
		status: PropTypes.string,
		className: PropTypes.string,
		actionsType: PropTypes.string,
		refresh: PropTypes.any,
	};

	render() {
		return (
			<StyledUserActions className={this.props.className}>
				<UserActionsListContainer {...this.props} />
			</StyledUserActions>
		);
	}
}

UserActions = enhanceComponent({
	UserActions,
});

export { UserActions };
