import { FC, useCallback, useEffect, useState } from 'react';
import { columnsService } from '@modules/table/columnsService';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';
import transactionsService from '../../../../../modules/transactions/transactionsService';

export const TableColumn: FC<{
	column: any;
	data: any;
}> = ({ column, data }) => {
	const prevColumn = usePrevious(
		column,
		(a, b) => !transactionsService.isDuplicate(a, b),
	);
	const prevData = usePrevious(
		data,
		(a, b) => !transactionsService.isDuplicate(a, b),
	);

	const getColumn = useCallback(() => {
		return columnsService.handleTableValue(column, data);
	}, [column, data]);

	const [Column, setColumn] = useState(getColumn());

	useEffect(() => {
		if (
			transactionsService.isDuplicate(
				{
					column,
					data,
				},
				{
					column: prevColumn,
					data: prevData,
				},
			)
		) {
			return;
		}
		setColumn(getColumn());
	}, [column, data, getColumn, prevColumn, prevData]);

	return Column;
};
