import { FC } from 'react';
import { Button, CSSClassNameButton } from '@ui/button';
import styled from 'styled-components';
import { textGradient } from '@legacyApp/client/modules/style/mixins';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { Copy } from '@icons/ui/copy';
import { CopyToClipboard } from '@common/components/CopyToClipboard';

export const BonusCode: FC<{
	code: string;
	noCopy?: boolean;
}> = ({ code, noCopy }) => {
	const content = (
		<BonusCodeStyled $noCopy={noCopy}>
			<div>{code}</div>

			{!noCopy && (
				<Button
					styleType={ButtonStyleType.CLEAR}
					name="input-copy-to-clipboard"
					iconBefore={
						<Copy
							fill={'#A66BF3'}
							fillMiddle={'#49B2C9'}
							fillStop={'#4956C9'}
						/>
					}
				/>
			)}
		</BonusCodeStyled>
	);

	if (noCopy) {
		return content;
	}

	return <CopyToClipboard value={code}>{content}</CopyToClipboard>;
};

export const BonusCodeStyled = styled.div<{
	$noCopy?: boolean;
}>`
	background: linear-gradient(
		93deg,
		#f7b757 1%,
		#f8c163 22.23%,
		#fbd591 36.73%,
		#f9c568 47.81%,
		#f9c568 73.2%
	);
	border: 1px inset rgba(255, 255, 255, 0.15);
	border-radius: 4px;
	cursor: ${(props) => (props.$noCopy ? 'unset' : 'pointer')};
	display: inline-flex !important;
	padding: 8px ${(props) => (props.$noCopy ? '8' : '32')}px 8px 8px;
	position: relative;
	width: auto !important;

	> div {
		${textGradient('var(--color-misc-rainbow)', 'rgb(166, 107, 243)')};
		font-size: 12px;
	}

	@media (min-width: ${(props) => props.theme.media.minWidthTablet}) {
		> div {
			font-size: 14px;
		}
	}

	.${CSSClassNameButton} {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
`;
