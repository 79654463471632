import { FunctionComponent } from 'react';
import { checkMobileType } from '@legacyApp/client/modules/app/appService';
import { BetType } from '@legacyApp/types/bets/BetType';
import { BetIdType } from '@legacyApp/types/bets/BetIdType';
import { useOpenBetModal } from '@legacyApp/hooks/bets/useOpenBetModal';
import { BetDetailsType } from '@legacyApp/types/bets/BetDetailsType';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { GameType } from '@modules/games/Game/constants/availableGames';
import { BetIdStyle } from './BetId.styled';

export interface BetIdProps {
	data: BetDetailsType;
	id: BetIdType;
	game: GameType;
	withGameName?: boolean;
	table?: boolean;
	type: BetType;
	onlyName?: boolean;
}

const BetIdString: FunctionComponent<BetIdProps> = ({
	data,
	id,
	game,
	withGameName,
	table,
	onlyName,
}) => {
	const device = useAppSelector((state) => state.app.deviceType);

	if (!data) {
		return (
			<>
				{withGameName ? `${game.toUpperCase()}:` : ''}#{id}
			</>
		);
	}

	if (onlyName) {
		return <>{data?.game_details?.name || data?.game?.name}</>;
	}

	if (table && checkMobileType(device) && typeof id === 'string') {
		return <>{id.split('-')[0]}</>;
	}

	return <>{id}</>;
};

const BetIdSimple: FunctionComponent<BetIdProps> = ({
	data,
	id,
	game,
	type,
	withGameName,
	table,
	onlyName,
}) => {
	const { openCallback } = useOpenBetModal({
		data,
		id,
		game,
		type,
	});

	return (
		<BetIdStyle $onlyName={onlyName} onClick={openCallback}>
			<BetIdString
				onlyName={onlyName}
				type={type}
				data={data}
				id={id}
				game={game}
				withGameName={withGameName}
				table={table}
			/>
		</BetIdStyle>
	);
};

export default BetIdSimple;
