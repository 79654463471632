import { FC } from 'react';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import { Rounded } from './Rounded';
import { CurrencyValue } from './CurrencyValue';

export const RoundedWithCurrency: FC<{
	value: number;
	currency: CurrencyType;
}> = ({ currency, value }) => {
	return (
		<CurrencyValue currency={currency}>
			<Rounded value={value} />
		</CurrencyValue>
	);
};
