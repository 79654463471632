import React from 'react';
import PropTypes from 'prop-types';
import { enhanceComponent } from '../../../wrappers/enhanceComponent';
import { Redirect } from '../../../../../common/components/default/Redirect/Redirect';
import { MODAL_ID } from '../../../../../common/constants/modal/ids.modal.constants';

class AffiliateCampaignName extends React.PureComponent {
	static propTypes = {
		id: PropTypes.any,
		name: PropTypes.string,
		data: PropTypes.object,
		openModal: PropTypes.func,
	};

	onClick = () => {
		this.props.openModal(MODAL_ID.AFFILIATE_CAMPAIGN, {
			id: this.props.id || undefined,
			data: this.props.data || undefined,
		});
	};

	render() {
		return (
			<Redirect className="text-underline" onClick={this.onClick}>
				{this.props.name}
			</Redirect>
		);
	}
}

AffiliateCampaignName = enhanceComponent({
	AffiliateCampaignName,
});

export { AffiliateCampaignName };
