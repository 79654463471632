import React, { FC } from 'react';
import { getNoResultsText } from '@legacyApp/client/modules/app/tableService';
import { StyledNoResultTableCell, StyledTableRow } from './Table.styled';

export const NoResultsElement: FC<{
	noResultsText: string;
	classic?: boolean;
}> = ({ noResultsText, classic }) => {
	return (
		<StyledTableRow as={classic ? 'tr' : 'div'}>
			<StyledNoResultTableCell as="span">
				{getNoResultsText(noResultsText)}
			</StyledNoResultTableCell>
		</StyledTableRow>
	);
};
