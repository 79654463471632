import { FC } from 'react';
import { BetRounded } from './BetRounded';
import { AmountWrapper, AmountWrapperProps } from './AmountWrapper';

interface BetAmountProps extends AmountWrapperProps {
	minValueToShortenThousands?: number;
	precise?: boolean;
}

export const BetAmount: FC<BetAmountProps> = (props) => {
	return (
		<AmountWrapper {...props}>
			<BetRounded
				precise={props.precise}
				value={props.value}
				minValueToShortenThousands={props.minValueToShortenThousands}
			/>
		</AmountWrapper>
	);
};
