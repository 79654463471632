import styled from 'styled-components';
import { config } from '@legacyApp/client/config';
import { mathService } from '@legacyApp/client/modules/math/mathService';

import { roundNumber } from '@common/methods/roundNumber/roundNumber';

export const getSplitValue = ({
	value,
}: {
	value: number;
}): {
	value: string;
	small: string;
} => {
	const big = mathService.currencyValue(
		value,
		undefined,
		config.decimals.bet,
		config.decimals.bet,
	);
	const small = value
		.toFixed(config.decimals.balance)
		.replace(
			roundNumber(value, config.decimals.bet, 'floor').toFixed(
				config.decimals.bet,
			),
			'',
		);

	// console.log('getSplitValue',{
	// 	valueStringified,
	// 	valueFixed,
	// 	value,
	// 	small,
	// });

	return {
		value: big,
		small,
	};
};

export const SplitValue = ({ amount }) => {
	const isNegative = amount < 0;
	const absolute = Math.abs(
		parseFloat(amount.toFixed(config.decimals.balance)),
	);

	const { value, small } = getSplitValue({ value: absolute });
	return (
		<StyledSplitValue>
			{isNegative ? '-' : ''}
			<span>{value}</span>
			<StyledSmallValue>{small}</StyledSmallValue>
		</StyledSplitValue>
	);
};

export const StyledSplitValue = styled.span`
	//margin-right: 12px;
	position: relative;
`;

export const StyledSmallValue = styled.span`
	display: inline-block;
	transform: scale(0.75) translate(-15%, 5%) !important;
`;
