import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { config } from '../../config';
import { trans } from '../translation/translate';

export const getAutoRowLimit = (table, limit, isDesktop) => {
	if (isDesktop && limit === 'auto') {
		return config.transactionsPaginationLength;
	}
	if (limit === 'auto') {
		limit = config.transactionsPaginationLength - 10;
	}
	if (!table || !table.current || !table.current.clientHeight) {
		return limit;
	}
	limit = roundNumber(
		table.current.clientHeight /
			table.current.querySelector('.table__row').clientHeight,
		0,
		'floor',
	);
	// console.log('getAutoLimit', {
	//   res: limit > 3 ? limit : config.transactionsPaginationLength,
	//   limit,
	//   clientHeight: table.current ? table.current.clientHeight : false
	// });
	return limit > 3 ? limit : config.transactionsPaginationLength - 10;
};

export const getNoResultsText = (text) => {
	return !text
		? trans({ label: 'No results' })
		: typeof text === 'string'
		? trans({ label: text })
		: text;
};
