import React, { FC } from 'react';
import {
	PayoutValue,
	PayoutValueProps,
} from '@common/components/number/PayoutValue';
import { GameType } from '../../../games/Game/constants/availableGames';

export const PayoutValueColumn: FC<{
	amount: number;
	game?: GameType;
	minDecimals?: PayoutValueProps['minDecimals'];
}> = ({ amount, game, minDecimals }) => {
	if (!Number(amount)) {
		return <>{'-'}</>;
	}
	return (
		<PayoutValue game={game} value={Number(amount)} minDecimals={minDecimals} />
	);
};
