import { FC } from 'react';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { config } from '@legacyApp/client/config';
import { countPrecision } from '@legacyApp/methods/math/countPrecision';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { PrettyNumber } from '../default/PrettyNumber/PrettyNumber';
import { SplitValue } from './SplitValue';

export const roundedNumber = (value: number, isInt?: boolean) => {
	const isNegative = value < 0;
	const absolute = Math.abs(parseFloat(value.toFixed(config.decimals.balance)));
	let _value = String(value);

	if (absolute >= 1000000) {
		_value = `${isNegative ? '-' : ''}${mathService.currencyValue(
			absolute / 1000,
			undefined,
			undefined,
			0,
		)}k`;
	}

	if (absolute < 1000000 && absolute >= 10000) {
		_value = `${isNegative ? '-' : ''}${mathService.currencyValue(
			absolute,
			undefined,
			undefined,
			0,
		)}`;
	}

	if (absolute < 10000 && absolute >= 1000) {
		_value = `${isNegative ? '-' : ''}${mathService.currencyValue(
			absolute,
			undefined,
			undefined,
			2,
		)}`;
	}

	if (absolute < 1000 && absolute >= 100) {
		_value = `${isNegative ? '-' : ''}${mathService.currencyValue(
			absolute,
			undefined,
			undefined,
			4,
		)}`;
	}

	if (absolute < 100 && absolute >= 10) {
		_value = `${isNegative ? '-' : ''}${mathService.currencyValue(
			absolute,
			undefined,
			undefined,
			5,
		)}`;
	}

	if (absolute < 10 && absolute >= 1) {
		_value = `${isNegative ? '-' : ''}${mathService.currencyValue(
			absolute,
			undefined,
			undefined,
			6,
		)}`;
	}

	if (absolute < 1) {
		_value = `${isNegative ? '-' : ''}${roundNumber(
			absolute,
			config.decimals.bet,
		).toFixed(config.decimals.bet)}`;
	}

	if (absolute === 0) {
		_value = absolute.toFixed(isInt ? 0 : config.decimals.bet);
	}

	return {
		isNegative,
		absolute,
		value: _value,
	};
};

export const Rounded: FC<{
	value: number;
	isInt?: boolean;
	minPretty?: number;
}> = ({ value, isInt, minPretty }) => {
	const { isNegative, absolute, value: value_ } = roundedNumber(value, isInt);

	// console.log('Rounded', {
	// 	maxPrecision,
	// 	absolute,
	// 	isNegative,
	// 	value,
	// });

	if (absolute === 0) {
		return <>{value_}</>;
	}

	if (absolute < 1 && absolute !== 0) {
		if (countPrecision(absolute) > config.decimals.bet) {
			return <SplitValue amount={value} />;
		}
	}

	const _minPretty = minPretty || 10000;

	if (absolute >= _minPretty) {
		if (isNegative) {
			return (
				<span>
					-
					<PrettyNumber params={{ shortFormat: true }}>{absolute}</PrettyNumber>
				</span>
			);
		}

		return (
			<PrettyNumber
				params={{
					shortFormat: true,
					shortFormatPrecision: 2,
					precision: 0,
					shortFormatMinValue: _minPretty,
				}}
			>
				{roundNumber(absolute, 0)}
			</PrettyNumber>
		);
	}

	return <>{value_}</>;
};
