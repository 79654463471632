import { FC } from 'react';
import { config } from '@legacyApp/client/config';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { GameType } from '@modules/games/Game/constants/availableGames';
import { game_config } from '@legacyApp/client/game_config';
import { CurrencyValue } from './CurrencyValue';

export interface PayoutValueProps {
	value: number;
	game: GameType;
	minDecimals?: number;
}

export const PayoutValue: FC<PayoutValueProps> = ({
	value,
	game,
	minDecimals,
}) => {
	// console.log('PayloadValue', { value, game });
	const precision = game_config?.[game]?.payoutDecimal
		? game_config[game].payoutDecimal
		: config.decimals.payout;
	return (
		<CurrencyValue>
			{mathService.currencyValue(value, undefined, minDecimals || 0, precision)}
			x
		</CurrencyValue>
	);
};
