import { connect } from 'react-redux';
import { openModal } from '../../../../../modules/modals/store/modal.actions';
import { AffiliateCampaignName } from './affiliateCampaignName';

const mapDispatchToProps = (dispatch) => {
	return {
		openModal: (name, props) =>
			dispatch(openModal(name, 'affiliateCampaignName', props)),
	};
};

const AffiliateCampaignNameContainer = connect(
	null,
	mapDispatchToProps,
)(AffiliateCampaignName);

export { AffiliateCampaignNameContainer };
