import { FC } from 'react';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { config } from '@legacyApp/client/config';
import { countPrecision } from '@legacyApp/methods/math/countPrecision';

import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { SplitValue } from './SplitValue';

export const BetRounded: FC<{
	value: number;
	minValueToShortenThousands?: number;
	precise?: boolean;
}> = ({ value, minValueToShortenThousands, precise }) => {
	const isNegative = value < 0;
	const absolute = Math.abs(
		parseFloat((value || 0).toFixed(config.decimals.balance)),
	);
	const precision = countPrecision(absolute);

	// console.log('BetRounded', {
	// 	absolute,
	// 	isNegative,
	// 	value,
	// });

	if (absolute === 0) {
		return <>{absolute.toFixed(config.decimals.bet)}</>;
	}

	if (precise) {
		return (
			<>
				{isNegative ? '-' : ''}
				{mathService.currencyValue(
					absolute,
					undefined,
					config.decimals.bet,
					config.decimals.bet,
				)}
			</>
		);
	}

	if (absolute < 1) {
		if (precision > config.decimals.bet) {
			return <SplitValue amount={value || 0} />;
		}
		return (
			<>
				{isNegative ? '-' : ''}
				{roundNumber(absolute, config.decimals.bet).toFixed(
					config.decimals.bet,
				)}
			</>
		);
	}

	if (absolute < 1000) {
		const decimals = precision > 2 ? 4 : 2;
		return (
			<>
				{isNegative ? '-' : ''}
				{mathService.currencyValue(absolute, undefined, decimals, decimals)}
			</>
		);
	}

	const decimals = precision > 0 ? 2 : 0;

	if (absolute < (minValueToShortenThousands || 10000)) {
		return (
			<>
				{isNegative ? '-' : ''}
				{mathService.currencyValue(absolute, undefined, decimals, decimals)}
			</>
		);
	}

	return (
		<>
			{isNegative ? '-' : ''}
			{mathService.currencyValue(
				absolute / 1000,
				undefined,
				decimals,
				decimals,
			)}
			k
		</>
	);
};
