import { FC, useCallback } from 'react';
import classnames from 'classnames';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { columnsService } from '@modules/table/columnsService';
import { isFunction } from '@common/methods/isFunction';
import { DIRECTION } from '@legacyApp/client/modules/app/sortService';
import { TableColumnConfig } from '@modules/table/types';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { ArrayElement } from '@common/types/ArrayElement.types';
import transactionService from '../../../../../modules/transactions/transactionsService';
import { TableColumn } from './tableColumn';
import { StyledTableCell } from './Table.styled';

import { TableProps } from './tableProps';

export interface TableRowContentProps<T = any> {
	columns: TableProps['columns'];
	el?: T;
	classic?: TableProps['classic'];
	compareKeys?: TableProps['rowCompareKeys'];
	cellAdd?: TableProps['headCellAdd'];
	onClick?: TableProps['headOnClick'];
	sortProps?: TableProps['sortProps'];
}

export interface TableCellContentProps<T = any>
	extends Omit<TableRowContentProps<T>, 'columns'> {
	column: ArrayElement<TableProps['columns']>;
}

export const SortAdd: FC<TableCellContentProps> = ({ sortProps, column }) => {
	if (!sortProps) {
		return null;
	}
	if (column.id.indexOf(sortProps.by) > -1) {
		return (
			<i
				className={`icon__arrow-${
					sortProps.direction === DIRECTION.ASC ? 'up' : 'down'
				} relative small-margin-left`}
			/>
		);
	}
	return null;
};

export const HeadCellAdd = <T = any,>({
	column,
	el,
	cellAdd,
	sortProps,
}: TableCellContentProps<T>): ReturnType<FC> => {
	if (!cellAdd && !sortProps) {
		return null;
	}
	const propsAdd = isFunction(cellAdd) ? cellAdd(column, el) : null;
	return (
		<>
			{propsAdd}
			{!!sortProps && <SortAdd column={column} sortProps={sortProps} />}
		</>
	);
};

export const CellContent = <T = any,>(
	props: TableCellContentProps<T>,
): ReturnType<FC> => {
	const { column, classic, el } = props;
	const Tag = classic ? 'tr' : 'div';

	return !el ? (
		<>
			{column.name.length && column.name.length > 1
				? trans({ label: column.name })
				: column.name}
			<HeadCellAdd {...props} />
		</>
	) : (
		<Tag className="text">
			<TableColumn column={column} data={el} />
			<HeadCellAdd {...props} />
		</Tag>
	);
};

export const TableRowContent = <T = any,>(
	props: TableRowContentProps<T>,
): ReturnType<FC> => {
	const { sortProps, columns, el, classic, compareKeys, onClick } = props;

	const columns_ = useStateEffect(
		useCallback(() => {
			return columns.map((column) => {
				const id = columnsService.parseJsonPathToColumnId(column.id);
				return {
					...column,
					className: classnames(`table__column--${id}`, {
						[column.columnClass]: !!column?.columnClass,
						[`table__column-sort--${!!sortProps && sortProps.direction}`]:
							!!sortProps && sortProps.direction && id === sortProps.by,
					}),
					key: `${id}_${transactionService.getCompareData(el, compareKeys)}`,
				};
			});
		}, [columns, compareKeys, el, sortProps]),
		(a, b) => transactionService.isListDifferent(a, b, ['key']),
	);

	const onClick_ = useCallback(
		(col: TableColumnConfig) => {
			if (isFunction(onClick)) {
				onClick(col);
			}
		},
		[onClick],
	);

	return (
		<>
			{columns_.map((column) => {
				const TagTd = classic ? 'td' : 'span';
				return (
					<StyledTableCell
						as={TagTd}
						onClick={() => onClick_(column)}
						key={column.key}
						className={column.className}
					>
						<CellContent {...props} column={column} />
					</StyledTableCell>
				);
			})}
		</>
	);
};
